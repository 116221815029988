export default class Hoverer {
  constructor({ app }) {
    this.app = app;

    this._item = null;
    this.filter = new Filter({ app });
  }

  update(event, isPicture = false) {
    // console.log('Hoverer.update=', this.item)
    // if (this.item?.data.imageMeta) {
    //   let sight = document.getElementById('sight')
    //   sight.style.display = 'none'
    // } else {
    //   let sight = document.getElementById('sight')
    //   sight.style.display = 'block'
    // }
    this.item = this.app.engineComponent.hoverer.pick(
      event,
      this.app.project,
      this,
      isPicture
    );
  }

  get item() {
    return this._item;
  }

  set item(value) {
    if (this._item === value) return;

    if (this._item) this._item.data.hovered.value = false;
    this._item = value;
    if (this._item) this._item.data.hovered.value = true;

    // console.log('set hovered item=', this._item)
  }
}

class Filter {
  constructor({ app }) {
    this.app = app;
    this.items = [];
  }

  add(item) {
    if (!item) {
      console.warn(this.constructor.name + '.add: null item');
      return;
    }

    if (!this.items?.includes(item)) {
      this.items.push(item);
    } else console.warn('item is already added=' + item.id, item);
  }

  remove(item) {
    if (!item) {
      console.warn(this.constructor.name + '.remove: null item');
      return;
    }

    const iIndex = this.items.indexOf(item);
    if (iIndex !== -1) {
      this.items.splice(iIndex, 1);
    } else console.warn('item is not found=' + item.id);
  }

  clear() {
    while (this.items.length > 0) {
      this.remove(this.items[0]);
    }
  }
}
